import Keycloak from "keycloak-js";
import { toast } from "react-toastify";
let keycloak;
let initPromise;
export function initializeKeycloak() {
  return new Promise((resolve, reject) => {
    keycloak = keycloak
      ? keycloak
      : new Keycloak({
          url: `https://auth.mettasocial.com/auth`,
          clientId:
            process.env.REACT_APP_ENV === "local-"
              ? `${process.env.REACT_APP_ENV}mettasocial`
              : `${process.env.REACT_APP_ENV}mettasocial-chat`,
          realm: "mettasocial-platform",
          useNativePromise: true,
        });
    let tempTokenHolder = null;
    const initOptions = {
      onLoad: "login-required",
      cors: "native",
      promiseType: "native",
      silentCheckSsoRedirectUri: false,
      checkLoginIframe: false,
      responseMode: "query",
    };
    if ((tempTokenHolder = localStorage.getItem("id_token"))) {
      initOptions.idToken = tempTokenHolder;
    }
    if ((tempTokenHolder = localStorage.getItem("access_token"))) {
      initOptions.token = tempTokenHolder;
    }
    if ((tempTokenHolder = localStorage.getItem("refresh_token"))) {
      initOptions.refreshToken = tempTokenHolder;
    }
    initPromise = initPromise ? initPromise : keycloak.init(initOptions);

    initPromise.then((authenticated) => {
      if (authenticated) {
        localStorage.setItem("access_token", keycloak.token);
        localStorage.setItem("refresh_token", keycloak.refreshToken);
        localStorage.setItem("id_token", keycloak.idToken);
        resolve({
          userDetails: keycloak.tokenParsed,
        });
        let dummyImg = document.createElement("img");
        dummyImg.setAttribute(
          "src",
          `https://uploads.mettasocial.com/download?d=${keycloak.idToken}`
        );
        document.body.appendChild(dummyImg);
        setTimeout(() => {
          document.body.removeChild(dummyImg);
          dummyImg = null;
        }, 1000);
      } else {
        toast.error("You are not authorized to see this page", {
          autoClose: 5000,
        });
        reject("authentication failure");
        setTimeout(() => {
          logout();
        }, 5000);
      }
    });
  });
}

export function updateToken(minvalidity) {
  return keycloak.updateToken(minvalidity);
}

export function token() {
  return keycloak.token;
}

export function isTokenExpired(minvalidity) {
  return keycloak.isTokenExpired(minvalidity);
}

export function login() {
  return keycloak.login();
}

export function parsedToken() {
  return keycloak.tokenParsed;
}

export function logout(e) {
  if (e) {
    e.preventDefault();
  }
  window.stop();
  localStorage.clear();
  sessionStorage.clear();
  keycloak.logout();
}
