import React from "react";
import "./MessagePage.css";
import { parsedToken, token } from "../helpers/keycloak";
import MessageList from "./MessageList";
import MessageDetails from "./MessageDetail";
const WebSocket = require("isomorphic-ws");

async function getConversations(threadId) {
  const response = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/getConversationsById?threadId=` +
      threadId,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token()}`,
      },
    }
  );
  return response.json();
}

async function getMessageThread() {
  const response = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/getMessageThreads`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token()}`,
      },
    }
  );
  return response.json();
}

async function markAsRead(threadId, messageId) {
  const response = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/markAsRead?threadId=${threadId}&messageId=${messageId}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token()}`,
      },
    }
  );
  return response.json();
}

async function getAllUsers() {
  const response = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/getAllUsers`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token()}`,
      },
    }
  );
  return response.json();
}

class MessagePage extends React.Component {
  constructor() {
    super();
    this.state = {
      conversation: null,
      messageThreads: null,
      selectedMessageId: null,
      contactName: null,
      contactId: null,
      ws: null,
      renderContacts: null,
      renderMessages: null,
      showImage: true,
    };
    this.OnMessageSelect = this.OnMessageSelect.bind(this);
    this.OnMessageSearch = this.OnMessageSearch.bind(this);
  }

  OnMessageSearch = (data) => {
    if (data) {
      this.setState({
        messageThreads: data,
      });
    }
  };

  OnStartNewConversation = () => {
    getAllUsers().then((data) => {
      this.setState({
        messageThreads: data.message,
      });
    });
  };

  OnMessageSelect = (threadId, contactName, contactId, messageId) => {
    this.setState({
      selectedMessageId: threadId,
      contactName: contactName,
      contactId: contactId,
      showImage: false,
    });
    if (messageId) markAsRead(threadId, messageId);
    getMessageThread().then((response) =>
      this.setState({ messageThreads: response.message })
    );
    getConversations(threadId).then((result) =>
      this.setState({ conversation: result.message })
    );
  };

  OnMessageSend = (data) => {
    const newArray = [data, ...this.state.conversation];
    this.setState({ ...this.state, conversation: newArray });
    return getMessageThread().then((response) =>
      this.setState({ messageThreads: response.message })
    );
  };

  GetMessageThreads = () => {
    getMessageThread().then((response) =>
      this.setState({ messageThreads: response.message })
    );
  };

  componentDidMount() {
    this.connect();
    getMessageThread().then((response) =>
      this.setState({ renderMessages: true, messageThreads: response.message })
    );
  }

  connect = () => {
    var ws = new WebSocket(
      `${process.env.REACT_APP_WS_BASE_URL}?Authorization=` + token()
    );
    let that = this; //cache this
    var connectInterval;

    ws.onopen = () => {
      this.setState({ ws: ws });
      that.timeout = 250;
      clearTimeout(connectInterval);
    };

    ws.onclose = () => {
      that.timeout = that.timeout + that.timeout;
      connectInterval = setTimeout(this.check, Math.min(10000, that.timeout));
    };

    ws.onmessage = (evt) => {
      const message = JSON.parse(evt.data);
      getMessageThread().then((response) => {
        this.setState({ messageThreads: response.message });
        if (message.threadId === this.state.selectedMessageId) {
          const newArray = [
            {
              content: message.message,
              messageType: "from",
              time: message.time,
            },
            ...this.state.conversation,
          ];
          this.setState({ ...this.state, conversation: newArray });
        }
      });
    };

    ws.onerror = (err) => {
      console.error(
        "Socket encountered error: ",
        err.message,
        "Closing socket"
      );
      ws.close();
    };
  };

  check = () => {
    const { ws } = this.state;
    if (!ws || ws.readyState === WebSocket.CLOSED) this.connect();
  };

  render() {
    return (
      <div className="row">
        <MessageList
          id={this.state.selectedMessageId}
          messageThreads={this.state.messageThreads}
          MessageSearch={this.OnMessageSearch}
          MessageSelect={this.OnMessageSelect}
          GetMessageThread={this.GetMessageThreads}
          StartNewConversation={this.OnStartNewConversation}
          renderContacts={this.state.renderContacts}
          renderMessages={this.state.renderMessages}
        />
        {this.state.showImage && (
          <img className="placeholder" src="/Placeholder.svg" alt="send" />
        )}
        {this.state.conversation && (
          <MessageDetails
            id={this.state.selectedMessageId}
            conversation={this.state.conversation}
            contactName={this.state.contactName}
            contactId={this.state.contactId}
            MessageSend={this.OnMessageSend}
            WebSocket={this.state.ws}
            UserDetails={parsedToken()}
          />
        )}
      </div>
    );
  }
}

export default MessagePage;
