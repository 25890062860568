import React from "react";
import PropTypes from "prop-types";
import Avatar from "./Avatar";
class MessageDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      conversations: null,
      contactName: null,
      message: "",
    };
  }
  static get propTypes() {
    return {
      conversation: PropTypes.any,
      WebSocket: PropTypes.any,
      contactId: PropTypes.any,
      UserDetails: PropTypes.any,
      MessageSend: PropTypes.any,
      contactName: PropTypes.any,
      id: PropTypes.number,
    };
  }
  componentDidMount() {
    this.setState({
      conversations: this.props.conversation,
    });
  }
  static getDerivedStateFromProps(props, state) {
    if (props.conversation !== state.conversations) {
      return {
        conversations: props.conversation,
      };
    }
    return null;
  }
  renderConversation = (conversations) => {
    if (!conversations) {
      return <div>Loading</div>;
    }
    if (conversations === "Token Expired") return <div>Token Expired</div>;

    return conversations.map((message) => (
      <Message
        key={message.time}
        message={message.content}
        time={message.time}
        messageType={message.messageType}
      />
    ));
  };

  handleKeyPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      this.sendMessage();
    }
  };

  handelMessage = (event) => {
    this.setState({
      message: event.target.value,
    });
  };

  sendMessage = () => {
    if (this.state.message) {
      const websocket = this.props.WebSocket; // websocket instance passed as props to the child component.
      websocket.send(
        JSON.stringify({
          action: "sendMessage",
          data: {
            to: this.props.contactId,
            toName: this.props.contactName,
            from: this.props.UserDetails.sub,
            message: this.state.message,
            fromName: this.props.UserDetails.name,
            threadId: this.props.id,
            userType: this.props.UserDetails.user_type,
            time: new Date().toUTCString(),
          },
        })
      );
      let data = {
        content: this.state.message,
        messageType: "to",
        time: new Date().toUTCString(),
      };
      this.props.MessageSend(data).then(() => {
        this.setState({
          message: "",
        });
      });
    }
  };

  render() {
    let contactName = this.props.contactName;
    return (
      <div className="col">
        <div className="d-flex flex-column msgdisplay_container">
          <div className="row">
            <div className="msgcontact_header">
              <div className="col pt-2">
                <Avatar name={contactName} />
                <span className="msgcontact_name font-weight-bold">
                  {contactName}
                </span>
              </div>
            </div>
          </div>
          <div className="row msgdisplay">
            <div className="col pl-0">
              <div className="msgdisplay_messages flex-grow-0">
                {this.renderConversation(this.state.conversations)}
              </div>
            </div>
          </div>
          <div className="row pl-0">
            <div className="col">
              <div className="row msgdetail_footer">
                <div className="col-9 col-md-10 col-lg-10 col-xl-11 col-sm-8">
                  <textarea
                    className="form-control"
                    name="textarea"
                    rows="1"
                    placeholder="Type a message..."
                    cols="1"
                    wrap="physical"
                    onChange={this.handelMessage}
                    onKeyDown={this.handleKeyPress}
                    value={this.state.message}
                  ></textarea>
                </div>
                <div className="col-3 col-md-2 col-lg-2 col-xl-1 col-sm-4">
                  <button
                    className="btn btn-primary p-1"
                    onClick={this.sendMessage}
                  >
                    <img
                      src="/white.svg"
                      alt="send"
                      width="100%"
                      height="100%"
                    ></img>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class Message extends React.Component {
  static get propTypes() {
    return {
      message: PropTypes.any,
      time: PropTypes.any,
      messageType: PropTypes.any,
    };
  }
  render() {
    let message = this.props.message;
    let time = new Date(this.props.time).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    let messageType = this.props.messageType;
    let messageClass = "";

    if (messageType === "to") {
      messageClass = "msgblock_to";
    } else if (messageType === "from") {
      messageClass = "msgblock_from";
    }
    return (
      <React.Fragment>
        <div className="row">
          {messageType === "to" && <div className="offset-6 offset-md-6"></div>}
          <div className="col-6 col-md-6 msgblock">
            <div className={messageClass}>
              <span>{message}</span>
              <span className="msgblock_time">{time}</span>
            </div>
          </div>
          {messageType === "from" && (
            <div className="offset-6 offset-md-6"></div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default MessageDetails;
