import PropTypes from "prop-types";
import React from "react";
import Modal from "../common/modal";
import { parsedToken, token } from "../helpers/keycloak";
import Avatar from "./Avatar";
import Loader from "./loader";
class MessageList extends React.Component {
  constructor() {
    super();
    this.state = {
      messages: null,
      searchedValue: "",
      allUsers: "",
    };
    this.messageThread = this.messageThread.bind(this);
  }
  static get propTypes() {
    return {
      id: PropTypes.any,
      messageThreads: PropTypes.any,
      GetMessageThread: PropTypes.func,
      MessageSelect: PropTypes.func,
      MessageSearch: PropTypes.func,
    };
  }
  componentDidMount() {
    this.setState({
      messages: this.props.messageThreads,
    });
    this.getAllUsers().then((resp) => {
      this.setState({
        allUsers: resp.message,
      });
    });
  }

  messageThread = () => {
    this.props.GetMessageThread();
  };

  componentDidUpdate(prevProps) {
    if (prevProps.messageThreads !== this.props.messageThreads) {
      this.setState({
        messages: this.props.messageThreads,
      });
    }
  }

  async getAllUsers() {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/getAllUsers`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token()}`,
        },
      }
    );
    return response.json();
  }

  static getDerivedStateFromProps(props, state) {
    if (props.messageThreads !== state.messages) {
      return {
        messages: props.messageThreads,
      };
    }
    return null;
  }

  renderMessagesView = (messageThread) => {
    if (!messageThread) {
      return <Loader loaderText="Loading..." />;
    }
    if (messageThread.length === 0) {
      return <div className="info">No Messages</div>;
    }
    if (messageThread.message === "Token Expired")
      return <div className="info">Token Expired</div>;
    return messageThread.map((contact) => (
      <MessageListItem
        selectMessage={this.props.MessageSelect}
        key={contact.id}
        id={contact.id}
        selectedMessageId={this.props.id}
        name={contact.contactName}
        message={contact.message}
        messageId={contact.messageId}
        from={contact.contactId}
        isReadCorp={contact.isReadCorp}
        isReadNgo={contact.isReadNgo}
        isReadAdmin={contact.isReadAdmin}
        time={contact.time}
      />
    ));
  };

  renderContacts = (contactList) => {
    if (!contactList) {
      return <Loader loaderText="Loading..." />;
    }
    if (contactList.length === 0) {
      return <div>No Contacts</div>;
    }
    if (contactList.message === "Token Expired")
      return <div>Token Expired</div>;
    return contactList.map((contact) => (
      <ContactListItem
        id={contact.id}
        key={contact.id}
        name={contact.contactName}
      />
    ));
  };

  searchContactName = (e) => {
    if (e.target.value === "") {
      this.props.GetMessageThread();
    }

    this.setState(
      {
        searchedValue: e.target.value,
      },
      () => {
        this.filterSearchItems();
      }
    );
  };

  filterSearchItems = () => {
    this.state.messages.forEach((message) => {
      if (
        message.contactName
          .toLowerCase()
          .includes(this.state.searchedValue.toLowerCase())
      ) {
        this.props.MessageSearch([message]);
      }
    });
  };

  handelNewConversation = () => (
    <Modal>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add New Contact
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {this.state.allUsers &&
                this.state.allUsers.map((data) => {
                  return (
                    <ContactListItem
                      id={data.id}
                      key={data.id}
                      name={data.contactName}
                      GetMessageThreadOnContactList={this.messageThread}
                    />
                  );
                })}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );

  render() {
    return (
      <>
        {this.handelNewConversation()}
        <div className="col-sm-12 col-md-4 col-xl-3 pr-0 msglist_container">
          <div className="d-flex flex-column h-100">
            <button
              className="msglist_startConversation"
              onClick={this.handelNewConversation}
              data-toggle="modal"
              data-target="#exampleModal"
            >
              +
            </button>
            <input
              type="text"
              placeholder="Search For Messages"
              className="msglist_search"
              onChange={(e) => this.searchContactName(e)}
              value={this.state.searchedValue}
            ></input>
            <div className="msgcontact_list">
              {this.renderMessagesView(this.state.messages)}
            </div>
          </div>
        </div>
      </>
    );
  }
}

class MessageListItem extends React.Component {
  static get propTypes() {
    return {
      id: PropTypes.number,
      selectMessage: PropTypes.func,
      from: PropTypes.string,
      messageId: PropTypes.number,
      message: PropTypes.string,
      time: PropTypes.string,
      name: PropTypes.string,
      isReadNgo: PropTypes.bool,
      isReadCorp: PropTypes.bool,
      isReadAdmin: PropTypes.bool,
      selectedMessageId: PropTypes.number,
    };
  }
  handelClick = () => {
    this.props.selectMessage(
      this.props.id,
      this.props.name,
      this.props.from,
      this.props.messageId
    );
  };

  render() {
    let userDetail = parsedToken();
    let time = this.props.time
      ? new Date(this.props.time).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })
      : "";
    let isRead = null;
    if (userDetail.user_type === 1) isRead = this.props.isReadAdmin;
    else if (userDetail.user_type === 2) isRead = this.props.isReadNgo;
    else if (userDetail.user_type === 4) isRead = this.props.isReadCorp;
    let itemClass = "";
    let nameClass = "";
    let msgClass = "";
    if (this.props.selectedMessageId === this.props.id) {
      itemClass = "msglist_msg_item selected";
      nameClass = "msglist_user_name selected";
      msgClass = "msglist_message selected";
    } else if (isRead) {
      itemClass = "msglist_msg_item read";
      nameClass = "msglist_user_name read";
      msgClass = "msglist_message read";
    } else {
      itemClass = "msglist_msg_item";
      nameClass = "msglist_user_name";
      msgClass = "msglist_message";
    }
    return (
      <React.Fragment>
        <div className={itemClass} onClick={this.handelClick}>
          <div className="row ">
            {/* <div className="msglist_img_thumbnail" style={{ backgroundImage: 'url(/logo.jpg)' }}></div> */}
            <div className="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 pr-0">
              <Avatar name={this.props.name} />
            </div>
            <div className="col-9 col-sm-9 col-md-9 col-lg-9 col-xl-9 pl-0">
              <span className={nameClass}>{this.props.name}</span>
              <span className="msglist_msg_datetime">{time}</span>
              <span>
                {!isRead && (
                  <div>
                    <div className="newMessageIndicator"></div>
                  </div>
                )}
              </span>
              <div>
                <span className={msgClass}>{this.props.message} </span>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

class ContactListItem extends React.Component {
  static get propTypes() {
    return {
      id: PropTypes.string,
      name: PropTypes.string,
      message: PropTypes.string,
      GetMessageThreadOnContactList: PropTypes.func,
    };
  }
  async handleUserClick() {
    await fetch(`${process.env.REACT_APP_API_BASE_URL}/addMessageThread`, {
      method: "POST",
      body: JSON.stringify({
        contactId: this.props.id,
        contactName: this.props.name,
      }),
      headers: {
        Authorization: `Bearer ${token()}`,
      },
    });
    this.props.GetMessageThreadOnContactList();
  }
  render() {
    return (
      <React.Fragment>
        <div
          className="contactlist_item"
          data-dismiss="modal"
          onClick={() => this.handleUserClick()}
        >
          <div className="row ">
            <div className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 pr-0">
              <Avatar name={this.props.name} />
            </div>
            <div className="col-10 col-sm-10 col-md-10 col-lg-10 col-xl-10 pl-0">
              <span className="contactlist_name">{this.props.name}</span>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default MessageList;
