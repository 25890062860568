import PropTypes from "prop-types";
import React from "react";
import "./loader.css";
const Loader = (props) => {
  return (
    <div className="loader-wrapper">
      <div className="loader">
        <img
          className="loader-image loader-image-1"
          src="http://cdn.mettasocial.com/logos/128_without_name_color.svg"
          alt=""
        />
        <img
          className="loader-image loader-image-2"
          src="http://cdn.mettasocial.com/logos/128_without_name_color.svg"
          alt=""
        />
        <img
          className="loader-image loader-image-3"
          src="http://cdn.mettasocial.com/logos/128_without_name_color.svg"
          alt=""
        />
        <h3 className="loader-text">{props.loaderText || "Loading"}</h3>
      </div>
    </div>
  );
};

export default Loader;

Loader.propTypes = {
  loaderText: PropTypes.string,
};

Loader.defaulProps = {
  loaderText: "Loading...",
};
