import React from "react";
import PropTypes from "prop-types";
class Avatar extends React.Component {
  static get propTypes() {
    return {
      name: PropTypes.string,
    };
  }
  render() {
    let name = this.props.name;
    let initials = name.match(/\b\w/g) || [];
    initials = (
      (initials.shift() || "") + (initials.pop() || "")
    ).toUpperCase();
    return <span className="msglist_avatar">{initials}</span>;
  }
}

export default Avatar;
