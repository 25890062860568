import React, { useState } from "react";
import { initializeKeycloak } from "../helpers/keycloak";
import Loader from "./loader";

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
}
export function securedHOC(Component) {
  const Wrappedcomponent = (props) => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    isAuthenticated === null &&
      initializeKeycloak()
        .then(() => {
          setIsAuthenticated(true);
        })
        .catch(() => {
          setIsAuthenticated(false);
        });
    if (isAuthenticated) {
      return <Component {...props} />;
    }
    return <Loader loaderText="Checking your login..." />;
  };
  Wrappedcomponent.displayName = `securedHOC(${getDisplayName(Component)})`;
  return Wrappedcomponent;
}
